import { FC, useCallback, useMemo } from 'react';
import DropdownSelect, { DropdownSize } from './form-control/DropdownSelect';
import CurrencyList from 'currency-list';
import { BaseControlRenderer, Item } from './form-control/DropdownDefaultComponents';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from '../../types/Languages';

type Props = {
  language?: SupportedLanguage;
  selectedCode: string;
  align?: 'left' | 'right';
  className?: string;
  onChange: (newCode: string) => void;
  disabled?: boolean;
  label?: string;
  size?: DropdownSize;
  isFixedPosition?: boolean;
};

const CurrencyDropdown: FC<Props> = (props) => {
  const { language, selectedCode, align = 'left', onChange, className, disabled, label, size, isFixedPosition } = props;

  const {
    i18n: { language: systemLanguage },
  } = useTranslation();

  const options = useMemo(() => {
    return Object.values(
      CurrencyList.getAll(language ?? systemLanguage) as {
        [code: string]: {
          name: string;
          symbol_native: string;
          symbol: string;
          code: string;
          name_plural: string;
          rounding: number;
          decimal_digits: number;
        };
      },
    ).map((x) => ({ id: x.code, text: x.name, value: x.symbol }));
  }, [language, systemLanguage]);

  const selectedOption = useMemo(() => {
    return options.find((x) => x.id === selectedCode);
  }, [options, selectedCode]);

  const searchFilter = useCallback((item: Item, search: string) => {
    const lowerSearch = search.toLocaleLowerCase();

    return (
      item.id.toLocaleLowerCase().indexOf(lowerSearch) > -1 ||
      item.text.toLocaleLowerCase().indexOf(lowerSearch) > -1 ||
      `${item.value}`.toLocaleLowerCase().indexOf(lowerSearch) > -1
    );
  }, []);

  return (
    <DropdownSelect
      customSearchFilter={searchFilter}
      wrapperClassName={className}
      value={selectedOption}
      options={options}
      onChange={(o) => onChange(o.id)}
      customSingleValueRenderer={SingleItemRenderer}
      customListRenderer={ListItemRenderer}
      menuPosition={align}
      disabled={disabled}
      customComponents={{ Control: (props) => <BaseControlRenderer {...props} className={className} /> }}
      label={label}
      size={size}
      isFixed={isFixedPosition}
    />
  );
};

const SingleItemRenderer: FC<Item> = (props) => {
  const { id, value } = props;
  return (
    <div className="font-medium">
      <span className="text-gray-2">{id}</span> <span className="text-primary-1">{value}</span>
    </div>
  );
};

const ListItemRenderer: FC<Item> = (props) => {
  const { id, text, value } = props;

  return (
    <div className="text-gray-2 flex min-w-[300px] items-center gap-4">
      <div className="inline-block w-[100px]">
        {id} <span className="text-primary-1">{value}</span>
      </div>
      <div className="w-[200px]">{text}</div>
    </div>
  );
};

export default CurrencyDropdown;
